import React, { useState } from "react";

import FilterWrapper from "components/common/filter-wrapper";
import BreadcrumbTrail from "components/common/breadcrumb-trail";
import "containers/providers/provider.scss";
import AdjustmentsFilter from "components/provider-adjustments/Filter";
import RecordsListing from "components/provider-adjustments/RecordsListing";

import "./unpaidAdjustmentReport.scss";

const UnpaidAdjustmentReport = () => {
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    paid_type: "unpaid",
    provider_id: "",
    earning_code_id: "",
    from: "",
    to: "",
  });

  const handleRefreshPage = () => {
    setRefresh((pre) => !pre);
  };
  const handleClickOnFilter = (values) => {
    setFilters(values);
    handleRefreshPage();
  };

  return (
    <>
      <div className="adjustments-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Unpaid Adjustment Report</h3>
            <BreadcrumbTrail />
          </div>
          <FilterWrapper>
            <AdjustmentsFilter
              handleSubmit={handleClickOnFilter}
              filters={filters}
              variant={"unpaid"}
            />
          </FilterWrapper>
          <RecordsListing
            refreshByParent={refresh}
            filters={filters}
            variant={"unpaid"}
          />
        </div>
      </div>
    </>
  );
};

export default UnpaidAdjustmentReport;
