import {PROVIDER_TABS} from 'constants/routePaths';
import * as yup from 'yup';
import {appendForMail} from './common';

const providerStatus = [
  {value: 'active', label: 'Active'},
  {value: 'inactive', label: 'Inactive'},
  {value: 'pending', label: 'Pending'},
  {value: 'currently_working', label: 'Currently Working'},
  {value: 'transitioning_out', label: 'Transitioning Out'},
  {value: 'leave_of_absence', label: 'Leave Of Absense'},
];
const tabsList = {
  [PROVIDER_TABS.INFORMATION]: {
    title: 'Provider Information',
    value: 'provider-information',
  },
  [PROVIDER_TABS.PAYROLL]: {
    title: 'Payroll Information',
    value: 'payroll-information',
  },
  [PROVIDER_TABS.RATES]: {
    title: 'Provider Rates',
    value: 'provider-rates',
  },
  [PROVIDER_TABS.FACILITIES]: {
    title: 'Assigned Facilities',
    value: 'assigned-facilities',
  },
  [PROVIDER_TABS.ADJUSTMENT]: {
    title: 'Session History',
    value: 'session-history',
  },
  [PROVIDER_TABS.CALENDAR]: {
    title: 'Calender',
    value: 'calendar',
  },
};

const providerInitialValues = {
  npi_code: '',
  provider_title: '',
  provider: '',
  first_name: '',
  last_name: '',
  entity: '',
  employement_status: '',
  regional_directors_attributes: [],
  company_email: '',
  personal_email: '',
  phone_number: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  service_line: null,
};

const payrollInitialValues = {
  provider_category: '',
  provider_category_label: '',
  base_salary: '',
  min_units_per_day: '',
  unit_rate: '',
  first_dos: '',
  last_dos: '',
  pay_roll_end_date: '',
  weekly_rvu_commitment: 0,
  pay_method: '',
  user: '',
  provider: '',
  pto_required: false,
  units_per_day: '',
  days_per_year: '',
};
const notesInitialValues = {
  notes: '',
};

const providerValidationSchema = yup.object().shape({
  npi_code: yup.number().test('len', 'NPI Code length must be 10 characters *', val => val?.toString()?.length === 10),
  phone_no: yup.string().matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Phone number is not valid *'),
  street: yup.string().required('Required *'),
  city: yup
    .string()
    .required('Required *')
    .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, 'city is not valid *'),
  state: yup.string().required('Required *'),
  // zip_code: yup.number().required('Required *'),
  // zip_code: yup
  //   .string()
  //   .required('Required')
  //   .matches(/^[0-9]{5}[\s.-]?\d{4}$/, 'zip code is not valid *'),
  zip_code: yup
    .string()
    .required('Required *')
    .test('len', 'Must be exactly 5 characters *', val => val?.length === 5),
  company_email: yup
    .string()
    .email('Must be valid email *')
    .required('Required *')
    .test({
      message: `Domain should be ${appendForMail}`,
      test: value => {
        value += '';
        return value.includes(appendForMail);
      },
    }),
    service_line:yup.object().required('Required *')
});
const payrollInformationValidationSchema = yup.object().shape({
  // base_salary: yup
  //   .string()
  //   .required('Required *')
  //   .test('Is Positive?', 'The number must be positive number!', value => value > 0),
  weekly_rvu_commitment: yup
    .number()
    .typeError('Must be a number')
    .test('is-decimal', 'Must be a number with up to 2 decimal places', value => /^\d+(\.\d{1,2})?$/.test(value)),
  base_salary: yup.string().when('provider_category_label', {
    is: 'Salary Based',
    then: yup
      .string()
      .required('Required *')
      .test('Is Positive?', 'The number must be a positive number!', value => parseFloat(value) >= 0),
    otherwise: yup.string(),
  }),

  min_units_per_day: yup.string().when('provider_category_label', {
    is: 'Salary Based',
    then: yup
      .string()
      .required('Required *')
      .test('Is Positive?', 'The number must be a positive number!', value => parseFloat(value) >= 0),
    otherwise: yup.string(),
  }),
  unit_rate: yup.string().when('provider_category_label', {
    is: 'Salary Based',
    then: yup
      .string()
      .required('Required *')
      .test('Is Positive?', 'The number must be a positive number!', value => parseFloat(value) >= 0),
    otherwise: yup.string(),
  }),
});
const createProviderInitialValues = {
  active: 1,
  npi_code: '',
  first_name: '',
  last_name: '',
  company_email: '',
  personal_email: '',
  phone_no: '',
  provider_and_titles_attributes: [],
  regional_directors_attributes: [],
  // entity_id: '',
  address_attributes: {
    state: '',
    zip_code: '',
    // country: '',
    street: '',
    city: '',
  },
  payroll_information_attributes: {
    base_salary: '',
    weekly_rvu_commitment: 0.0,
    min_units_per_day: '',
    unit_rate: '',
    first_dos: '',
    last_dos: '',
    pay_roll_end_date: '',
    provider_category_id: '',
    provider_category_label: '',
    pay_method_id: '',
    user_id: '',
  },
};
const createProviderValidationSchema = yup.object().shape({
  npi_code: yup.number().test('len', 'NPI Code length must be 10 characters *', val => val?.toString()?.length === 10),
  first_name: yup.string().required('Required *'),
  last_name: yup.string().required('Required *'),
  company_email: yup
    .string()
    .email('Must be valid email *')
    .required('Required *')
    .test({
      message: `Domain should be ${appendForMail}`,
      test: value => {
        value += '';
        return value.includes(appendForMail);
      },
    }),
  personal_email: yup.string().email('Must be valid email *').required('Required *'),
  // entity_id: yup.string().required('Required *'),
  provider_and_titles_attributes: yup.array().min(1, 'At least one title must be selected').required('Required *'),
  phone_no: yup
    .string()
    .required('Required *')
    .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Phone number is not valid *'),
  address_attributes: yup.object().shape({
    street: yup.string().required('Required *'),
    city: yup
      .string()
      .required('Required *')
      .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, 'city is not valid *'),
    state: yup
      .string()
      .required('Required *')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed *'),
    zip_code: yup
      .string()
      .required('Required *')
      .test('len', 'Must be exactly 5 characters *', val => val?.toString()?.length === 5),
    // country: yup.string().required('Required *'),
  }),
  payroll_information_attributes: yup.object().shape({
    weekly_rvu_commitment: yup
      .number()
      .typeError('Must be a number')
      .test('is-decimal', 'Must be a number with up to 2 decimal places', value => /^\d+(\.\d{1,2})?$/.test(value)),

    //   base_salary: yup
    //     .number()
    //     .typeError('Invalid *')
    //     // .required('Required *')
    //     .test('Is Positive?', 'The number must be greater than 0!', value => value === undefined || value > 0),
    //   min_units_per_day: yup
    //     .number()
    //     .typeError('Invalid *')
    //     // .required('Required *')
    //     .test('Is Positive?', 'The number must be greater than 0!', value => value === undefined || value > 0),
    //   unit_rate: yup
    //     .number()
    //     // .required('Required *')
    //     .test('Is Positive?', 'The number must be greater than 0!', value => value === undefined || value > 0),
    //   paid_time_off_per_year: yup
    //     .number()
    //     // .required('Required *')
    //     .test('Is Positive?', 'The number must be greater than 0!', value => value === undefined || value > 0),
    //   paid_time_off_balance: yup
    //     .number()
    //     // .required('Required *')
    //     .test('Is Positive?', 'The number must be greater than 0!', value => value === undefined || value > 0),
    //   // first_dos: yup.date().required('Required *'),
    //   // last_dos: yup.date().required('Required *'),
    //   // pay_roll_end_date: yup.date().required('Required *'),
    //   // provider_category_id: yup.string().required('Required *'),
    //   // pay_method_id: yup.string().required('Required *'),
    //   // user_id: yup.string().required('Required *'),
  }),
});
const notesValidationSchema = yup.object().shape({
  notes: yup.string().required('Required *'),
});
const paymentEntityValidationSchema = yup.object().shape({
  entity_id: yup.string().required('Required *'),
  adp_number: yup.string().required('Required *'),
});
export {
  providerStatus,
  tabsList,
  providerInitialValues,
  payrollInitialValues,
  providerValidationSchema,
  payrollInformationValidationSchema,
  createProviderInitialValues,
  createProviderValidationSchema,
  notesInitialValues,
  notesValidationSchema,
  paymentEntityValidationSchema,
};
