import React, {useEffect, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';
import {ArrowsDownUp, Minus, Plus} from 'phosphor-react';

import {DEBOUNCE_DELAY, getActionButtonObj, getCurrencyAndFormattedValue} from 'utils/common';
import {initialMetaForTable, orderStratergy} from 'constants/common';
import {createActiveAnalysisEmail} from 'containers/activity-analysis-paid/api';
// import {Tooltip, OverlayTrigger} from 'components/common/tooltip';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import _ from 'lodash';

let timeout;
function formatDollarStringToTwoDecimalPlaces(input) {
  // Ensure the input is treated as a string
  const inputString = String(input);

  // Remove the dollar sign and any surrounding whitespace
  const numberString = inputString.replace(/\$/g, '').trim();

  // Convert the string to a floating-point number
  const number = parseFloat(numberString);

  // Check if the conversion resulted in a valid number
  if (isNaN(number)) {
    return input;
  }

  // Return the number formatted to two decimal places as a string
  return '$' + number.toFixed(2);
}

function formatStringToTwoDecimalPlaces(input) {
  // Ensure the input is treated as a string
  const inputString = String(input);

  // Remove the dollar sign and any surrounding whitespace
  const numberString = inputString.replace(/\$/g, '').trim();

  // Convert the string to a floating-point number
  const number = parseFloat(numberString);

  // Check if the conversion resulted in a valid number
  if (isNaN(number)) {
    return input;
  }

  // Return the number formatted to two decimal places as a string
  return '$' + number.toFixed(2);
}

const RecordsListing = ({data, payload}) => {
  const [recordsListing, setRecordsLiting] = useState(
    data?.map(item => ({...item, isNested: false, recordId: item.name + Math.random()})),
  );
  const [refreshPage, setRefreshPage] = useState(false);
  const [totalCount, setTotalCount] = useState(data.length);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };

  const getElementPosition = name => {
    let el = document.getElementById(name);
    console.log(
      'window.pageYOffset + el?.getBoundingClientRect().top;',
      el?.getBoundingClientRect().top.toString() + 'px',
    );
    // return (el?.getBoundingClientRect().top - 300).toString() + 'px';
    return '500px';
  };
  const handleSendEmail = async data => {
    const result = await createActiveAnalysisEmail(data);
  };

  const sortedDataByColumn = columnName => {
    if (recordsListing.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      setRecordsLiting(pre => {
        if (meta.order === 'asc') {
          return pre.sort((a, b) => b.name.localeCompare(a.name));
        } else {
          return pre.sort((a, b) => a.name.localeCompare(b.name));
        }
      });
      // handleRefreshPage();
    }
  };

  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={[
          getActionButtonObj('Send Email', () => {
            handleSendEmail(payload);
          }),
        ]}
        customActionButton={
          recordsListing.every(item => item.isNested)
            ? () => (
                <div className="icon-otr  cursor-pointer">
                  {/* {recordsListing.every(item => item.isNested) ? ( */}
                  {/* <OverlayTrigger
                    placement="bottom"
                    delay={{show: 250, hide: 400}}
                    overlay={props => (
                      <Tooltip id="button-tooltip-collaps" {...props}>
                        collaps all
                      </Tooltip>
                    )}
                  > */}
                  <Minus
                    size={24}
                    onClick={() => {
                      setRecordsLiting(pre =>
                        pre.map(elem => {
                          return {...elem, isNested: false};
                        }),
                      );
                    }}
                  />
                  {/* </OverlayTrigger> */}
                </div>
              )
            : () => (
                <div className="icon-otr  cursor-pointer">
                  <OverlayTrigger
                    placement="bottom"
                    delay={{show: 250, hide: 400}}
                    overlay={props => (
                      <Tooltip id="button-tooltip-expand" {...props}>
                        expand all
                      </Tooltip>
                    )}
                  >
                    <Plus
                      size={24}
                      onClick={() => {
                        setRecordsLiting(pre =>
                          pre.map(elem => {
                            return {...elem, isNested: true};
                          }),
                        );
                      }}
                    />
                  </OverlayTrigger>
                </div>
              )
        }
      >
        {loading ? (
          <p style={{textAlign: 'center'}}>Loading</p>
        ) : (
          <table className="table main-nested ">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb bolder" onClick={() => sortedDataByColumn('name')}>
                      Clinician Name
                      {/* <ArrowsDownUp className="ms-2" size={18} /> */}
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">NPI Code</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Provider Category</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">From Date</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">To Date</p>
                  </div>
                </th>

                <th scope="col" className="action-column ">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Actions</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {recordsListing.map(item => {
                return (
                  <>
                    <tr key={item.id} className={`${item.isNested ? 'nested-table table-active' : 'table-active'}`}>
                      <th scope="row">
                        <div className="table-text-otr ">
                          <span className="table-text bold">{item?.name || ''}</span>
                        </div>
                      </th>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text bold">{item?.npi_code || ''}</p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text bold">{item?.provider_category_name}</p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text bold">{item?.start_date || ''}</p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text bold">{item?.end_date || ''}</p>
                        </div>
                      </td>

                      <td className="action-column">
                        <div className="table-icon-otr">
                          <div className="icon-otr me-3">
                            {item.isNested ? (
                              <Minus
                                size={24}
                                onClick={() => {
                                  setRecordsLiting(pre =>
                                    pre.map(elem => {
                                      if (elem.recordId === item.recordId) {
                                        return {...elem, isNested: false};
                                      }
                                      return elem;
                                    }),
                                  );
                                }}
                              />
                            ) : (
                              <Plus
                                size={24}
                                onClick={() => {
                                  setRecordsLiting(pre =>
                                    pre.map(elem => {
                                      if (elem.recordId === item.recordId) {
                                        return {...elem, isNested: true};
                                      }
                                      return elem;
                                    }),
                                  );
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </td>
                      <th></th>
                    </tr>
                    {item.isNested && item?.service_data?.length > 0 && (
                      <tr className="nested-parent-tr">
                        <td colSpan={6} className="nested-parent-td">
                          <div className="nested-table-wrapper app-scroller ">
                            <table
                              className={`table nested-table ${
                                Object.keys(item.service_data[0]).length > 6 ? 'scroll-table' : ''
                              }`}
                            >
                              <thead id="nested-table-header">
                                <tr>
                                  {Object.keys(item.service_data[0])
                                    ?.reverse()
                                    .map((key, headIndex) => {
                                      return (
                                        <th scope="col">
                                          <div className="header-text-otr">
                                            <p className="table-name heading-xsb">
                                              {key.replace('_', ' ').toUpperCase()}
                                            </p>
                                          </div>
                                        </th>
                                      );
                                    })}
                                </tr>
                              </thead>
                              <tbody>
                                {item?.service_data?.map(elem => {
                                  return (
                                    <>
                                      <tr key={item.id} className={`${item.isNested ? '' : ''}`}>
                                        {Object.keys(elem)
                                          ?.reverse()
                                          .map((elemKey, index) => {
                                            return index === 0 ? (
                                              <th
                                                scope="row"
                                                style={{paddingTop: '8px', paddingBottom: '8px'}}
                                                // style={{padding: '10px'}}
                                              >
                                                <div className="table-text-otr ">
                                                  <span className="table-text">{elem[elemKey] || '-'}</span>
                                                </div>
                                              </th>
                                            ) : (
                                              <td
                                                style={{paddingTop: '8px', paddingBottom: '8px'}}
                                                // style={{padding: '10px'}}
                                              >
                                                <div className="table-text-otr">
                                                  <p className="table-text">{elem[elemKey] || '-'}</p>
                                                </div>
                                              </td>
                                            );
                                          })}
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                              <tfoot>
                                <tr className="nested-table-footer">
                                  <th colSpan={2}>
                                    <div className="table-text-otr ">
                                      <span className="table-name heading-xsb">{'Total' || ''}</span>
                                    </div>
                                  </th>
                                  {Object.keys(item.service_data_total)
                                    ?.reverse()
                                    .map((elemKey, index) => {
                                      return (
                                        <td key={index}>
                                          <div className="table-text-otr">
                                            <p className="table-name heading-xsb">
                                              {item.service_data_total[elemKey] || ''}
                                            </p>
                                          </div>
                                        </td>
                                      );
                                    })}
                                </tr>
                                {!(
                                  typeof item?.salary_contract === 'object' &&
                                  Object.keys(item?.salary_contract)?.length
                                ) ? (
                                  <tr className="nested-table-footer">
                                    <th colSpan={2}>
                                      <div className="table-text-otr ">
                                        <span className="table-name heading-xsb">{'Total Charges' || ''}</span>
                                      </div>
                                    </th>
                                    {Object.keys(item.service_data_total_amount)
                                      ?.reverse()
                                      .map((elemKey, index) => {
                                        return (
                                          <td key={index}>
                                            <div className="table-text-otr">
                                              <p className="table-name heading-xsb">
                                                {formatDollarStringToTwoDecimalPlaces(
                                                  item.service_data_total_amount[elemKey] || '',
                                                )}
                                              </p>
                                            </div>
                                          </td>
                                        );
                                      })}
                                  </tr>
                                ) : null}
                              </tfoot>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )}

                    {/* second nested table  */}

                    {item.isNested && item?.service_data_by_facility_entity?.length > 0 && (
                      <tr className="nested-parent-tr">
                        <td colSpan={6}>
                          <table className="table nested-table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Entity</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Facility</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  {/* <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Location</p>
                                  </div>*/}
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Hours</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Units</p>
                                  </div>
                                </th>
                                {!(
                                  typeof item?.salary_contract === 'object' &&
                                  Object.keys(item?.salary_contract)?.length
                                ) ? (
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Total Amount</p>
                                    </div>
                                  </th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody>
                              {item?.service_data_by_facility_entity?.map(elem => {
                                return (
                                  <>
                                    <tr key={item.id} className={`${item.isNested ? '' : ''}`}>
                                      <th
                                        scope="row"
                                        style={{paddingTop: '8px', paddingBottom: '8px'}}
                                        // style={{padding: '10px'}}
                                      >
                                        <div className="table-text-otr ">
                                          <span className="table-text">{elem?.entity_name || ''}</span>
                                        </div>
                                      </th>

                                      <td>
                                        <div className="table-text-otr" style={{width: 'auto'}}>
                                          <p className="">{elem?.facility_name || ''}</p>
                                        </div>
                                      </td>
                                      <td>
                                        {/*  <div className="table-text-otr">
                                          <p className="table-text">{elem?.location || ''}</p>
                                        </div>*/}
                                      </td>
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.total_hours || ''}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.total_serviced_unit || ''}</p>
                                        </div>
                                      </td>
                                      {!(
                                        typeof item?.salary_contract === 'object' &&
                                        Object.keys(item?.salary_contract)?.length
                                      ) ? (
                                        <td>
                                          <div className="table-text-otr">
                                            <p className="table-text">
                                              {`$${Number(elem?.total_amount)?.toFixed(2)}` || ''}
                                            </p>
                                          </div>
                                        </td>
                                      ) : null}
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}

                    {/* third nested table  */}

                    {item.isNested && item?.service_data_adjustments?.length > 0 && (
                      <>
                        <tr className="nested-parent-tr ">
                          <td colSpan={6}>
                            <p className="table-name heading-xsb adjustment-table-header ">Adjustment</p>
                          </td>
                        </tr>
                        <tr className="nested-parent-tr " style={{borderRadius: '0px'}}>
                          <td colSpan={6}>
                            <table
                              className="table 
                            nested-table 
                            adjustment-table-body  
                            "
                            >
                              <thead>
                                <tr className="text-danger">
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Effective Date</p>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Service Date</p>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Facility</p>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Code</p>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Units</p>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Charge Amount</p>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {item?.service_data_adjustments?.map(elem => {
                                  return (
                                    <>
                                      <tr key={item.id}>
                                        <th scope="row" style={{paddingTop: '8px', paddingBottom: '8px'}}>
                                          <div className="table-text-otr ">
                                            <span className="table-text">{elem?.effective_date || ''}</span>
                                          </div>
                                        </th>

                                        <td>
                                          <div className="table-text-otr">
                                            <p className="table-text">{elem?.service_date || ''}</p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="table-text-otr">
                                            <p className="table-text" style={{maxWidth: '400px'}}>
                                              {elem?.facility_name || ''}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="table-text-otr">
                                            <p className="table-text">{elem?.earning_code || ''}</p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="table-text-otr">
                                            <p className="table-text">{elem?.service_units || ''}</p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="table-text-otr">
                                            <p className="table-text">
                                              {getCurrencyAndFormattedValue(elem?.charge_amount)}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                                <tr className="nested-table-footer">
                                  <th colSpan={6}>
                                    {' '}
                                    <div className="table-text-otr ">
                                      <span className="table-name heading-xsb">{'Total Adj.' || ''}</span>
                                    </div>
                                  </th>
                                  <td>
                                    <div className="table-text-otr">
                                      <p className="table-name heading-xsb">
                                        {'$ ' +
                                          item?.service_data_adjustments
                                            .reduce(
                                              (pre, current) =>
                                                Number(pre) + Number(current['charge_amount'].replace('$', '')),
                                              0,
                                            )
                                            .toFixed(2) || ''}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </>
                    )}

                    {item.isNested &&
                      _.isObject(item?.salary_contract) &&
                      Object.keys(item?.salary_contract)?.length > 0 && (
                        <tr className="nested-parent-tr">
                          <td colSpan={6}>
                            <table className="table nested-table">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Salary contract</p>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb"></p>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(item?.salary_contract).map(elem => {
                                  return (
                                    <>
                                      <tr key={item.id} className={`${item.isNested ? '' : ''}`}>
                                        <th
                                          scope="row"
                                          style={{paddingTop: '8px', paddingBottom: '8px'}}
                                          // style={{padding: '10px'}}
                                        >
                                          <div className="table-text-otr ">
                                            <span className="table-text">{elem || ''}</span>
                                          </div>
                                        </th>
                                        <td>
                                          <div>
                                            <p className="table-text">{item?.salary_contract[elem] || '-'}</p>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}

                    {/* fivth table  */}

                    {item.isNested && item?.service_data_by_earning_code?.length > 0 && (
                      <tr className="nested-parent-tr">
                        <td colSpan={6}>
                          <table className="table nested-table mb-4">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Procedure Code</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Procedure Name</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Units</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">RVU Value</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Total RVUs</p>
                                  </div>
                                </th>

                                {!(
                                  typeof item?.salary_contract === 'object' &&
                                  Object.keys(item?.salary_contract)?.length
                                ) ? (
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Unit Amount</p>
                                    </div>
                                  </th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody>
                              {item?.service_data_by_earning_code?.map(elem => {
                                return (
                                  <>
                                    <tr key={item.id} className={`${item.isNested ? '' : ''}`}>
                                      <th
                                        scope="row"
                                        style={{paddingTop: '8px', paddingBottom: '8px'}}
                                        // style={{padding: '10px'}}
                                      >
                                        <div className="table-text-otr ">
                                          <span className="table-text">{elem?.earning_code || ''}</span>
                                        </div>
                                      </th>
                                      <td>
                                        <div>
                                          <p className="table-text">{elem?.earning_code_name || ''}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <p className="table-text">{elem?.service_units}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <p className="table-text">{elem?.unit_multiplier}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <p className="table-text">{elem?.total_rvus}</p>
                                        </div>
                                      </td>

                                      {!(
                                        typeof item?.salary_contract === 'object' &&
                                        Object.keys(item?.salary_contract)?.length
                                      ) ? (
                                        <td>
                                          <div className="table-text-otr">
                                            <p className="table-text">
                                              {`$${Number(elem?.initial_unit_charge_amount)?.toFixed(2)}` || ''}
                                            </p>
                                          </div>
                                        </td>
                                      ) : null}
                                    </tr>
                                  </>
                                );
                              })}
                              <tr className="nested-table-footer">
                                <th
                                  colSpan={
                                    typeof item?.salary_contract === 'object' &&
                                    Object.keys(item?.salary_contract)?.length
                                      ? 4
                                      : 5
                                  }
                                >
                                  {' '}
                                  <div className="table-text-otr ">
                                    <span className="table-name heading-xsb">{'RVU Total' || ''}</span>
                                  </div>
                                </th>
                                <th
                                  colSpan={
                                    typeof item?.salary_contract === 'object' &&
                                    Object.keys(item?.salary_contract)?.length
                                      ? 4
                                      : 5
                                  }
                                >
                                  <div className="table-text-otr margin-col">
                                    <p className="table-name heading-xsb">
                                      {formatStringToTwoDecimalPlaces(item?.total_rvus || '')}
                                    </p>
                                  </div>
                                </th>
                              </tr>
                              <tr className="nested-table-footer">
                                <th
                                  colSpan={
                                    typeof item?.salary_contract === 'object' &&
                                    Object.keys(item?.salary_contract)?.length
                                      ? 4
                                      : 5
                                  }
                                >
                                  {' '}
                                  <div className="table-text-otr ">
                                    <span className="table-name heading-xsb">{'Gross Total' || ''}</span>
                                  </div>
                                </th>
                                <th
                                  colSpan={
                                    typeof item?.salary_contract === 'object' &&
                                    Object.keys(item?.salary_contract)?.length
                                      ? 4
                                      : 5
                                  }
                                >
                                  <div className="table-text-otr margin-col">
                                    <p className="table-name heading-xsb">
                                      {formatDollarStringToTwoDecimalPlaces(item?.gross_total || '')}
                                    </p>
                                  </div>
                                </th>
                              </tr>
                              <tr className="nested-table-footer">
                                <th
                                  colSpan={
                                    typeof item?.salary_contract === 'object' &&
                                    Object.keys(item?.salary_contract)?.length
                                      ? 4
                                      : 5
                                  }
                                >
                                  {' '}
                                  <div className="table-text-otr ">
                                    <span className="table-name heading-xsb">{'Adjustment Total' || ''}</span>
                                  </div>
                                </th>
                                <th
                                  colSpan={
                                    typeof item?.salary_contract === 'object' &&
                                    Object.keys(item?.salary_contract)?.length
                                      ? 4
                                      : 5
                                  }
                                >
                                  <div className="table-text-otr margin-col">
                                    <p className="table-name heading-xsb">
                                      {formatDollarStringToTwoDecimalPlaces(item?.rollover_total || '')}
                                    </p>
                                  </div>
                                </th>
                              </tr>
                              <tr className="nested-table-footer">
                                <th
                                  colSpan={
                                    typeof item?.salary_contract === 'object' &&
                                    Object.keys(item?.salary_contract)?.length
                                      ? 4
                                      : 5
                                  }
                                >
                                  {' '}
                                  <div className="table-text-otr ">
                                    <span className="table-name heading-xsb">{'Adjusted Total' || ''}</span>
                                  </div>
                                </th>
                                <th
                                  colSpan={
                                    typeof item?.salary_contract === 'object' &&
                                    Object.keys(item?.salary_contract)?.length
                                      ? 4
                                      : 5
                                  }
                                >
                                  <div className="table-text-otr margin-col">
                                    <p className="table-name heading-xsb">
                                      {formatDollarStringToTwoDecimalPlaces(item?.net_total || '')}
                                    </p>
                                  </div>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
    </>
  );
};

export default RecordsListing;
// const testData = [
//   {
//     name: 'Test 1',
//     npi_code: '1234567890',
//     provider_category_name: 'Independent Contractor (W9)',
//     start_date: '01/01/2021',
//     end_date: '01/01/2023',
//     service_data: [
//       {
//         44444: 0,
//         44446: '500.0',
//         service_date: '01/15/2023',
//         facility_name: 'CA Outpatient Telehealth',
//       },
//     ],
//     service_data_total: {
//       44444: 0,
//       44446: 500,
//     },
//     service_data_total_amount: {
//       44444: '$0',
//       44446: '$500.0',
//     },
//     service_data_by_facility_entity: [
//       {
//         entity_name: 'ENT 1 CA Healthcare',
//         facility_name: 'CA Outpatient Telehealth',
//         location: 'CA',
//         total_hours: '10.0',
//         total_serviced_unit: 500,
//         total_amount: '500.0',
//       },
//     ],
//     service_data_by_earning_code: [
//       {
//         earning_code: '44446',
//         earning_code_name: 'RM APPROVED PSYCHIATRY INTERVIEW',
//         initial_unit_charge_amount: '1.0',
//         service_units: 500,
//         unit_multiplier: 1,
//         total_rvus: 500,
//       },
//     ],
//     service_data_adjustments: [
//       {
//         service_date: '01/25/2023',
//         effective_date: '01/25/2023',
//         facility_name: 'CA Outpatient Telehealth',
//         earning_code: '44444',
//         service_units: 2,
//         charge_amount: '$0.0',
//       },
//       {
//         service_date: '01/25/2023',
//         effective_date: '01/20/2023',
//         facility_name: 'CA Outpatient Telehealth',
//         earning_code: '44446',
//         service_units: 10,
//         charge_amount: '$10.0',
//       },
//     ],
//     salary_contract: [],
//     total_rvus: 500,
//     gross_total: '$500.0',
//     rollover_total: '$10.0',
//     net_total: '$510.0',
//   },
//   {
//     name: 'Test 2',
//     npi_code: '2345678901',
//     provider_category_name: 'Independent Contractor (W9)',
//     start_date: '02/01/2021',
//     end_date: '02/01/2023',
//     service_data: [
//       {
//         44444: 0,
//         44446: '600.0',
//         service_date: '02/15/2023',
//         facility_name: 'NY Outpatient Telehealth',
//       },
//     ],
//     service_data_total: {
//       44444: 0,
//       44446: 600,
//     },
//     service_data_total_amount: {
//       44444: '$0',
//       44446: '$600.0',
//     },
//     service_data_by_facility_entity: [
//       {
//         entity_name: 'ENT 2 NY Healthcare',
//         facility_name: 'NY Outpatient Telehealth',
//         location: 'NY',
//         total_hours: '12.0',
//         total_serviced_unit: 600,
//         total_amount: '600.0',
//       },
//     ],
//     service_data_by_earning_code: [
//       {
//         earning_code: '44446',
//         earning_code_name: 'RM APPROVED PSYCHIATRY INTERVIEW',
//         initial_unit_charge_amount: '1.0',
//         service_units: 600,
//         unit_multiplier: 1,
//         total_rvus: 600,
//       },
//     ],
//     service_data_adjustments: [
//       {
//         service_date: '02/25/2023',
//         effective_date: '02/25/2023',
//         facility_name: 'NY Outpatient Telehealth',
//         earning_code: '44444',
//         service_units: 3,
//         charge_amount: '$0.0',
//       },
//       {
//         service_date: '02/25/2023',
//         effective_date: '02/20/2023',
//         facility_name: 'NY Outpatient Telehealth',
//         earning_code: '44446',
//         service_units: 15,
//         charge_amount: '$15.0',
//       },
//     ],
//     salary_contract: [],
//     total_rvus: 600,
//     gross_total: '$600.0',
//     rollover_total: '$15.0',
//     net_total: '$615.0',
//   },
//   {
//     name: 'Test 3',
//     npi_code: '3456789012',
//     provider_category_name: 'Independent Contractor (W9)',
//     start_date: '03/01/2021',
//     end_date: '03/01/2023',
//     service_data: [
//       {
//         44444: 0,
//         44446: '700.0',
//         service_date: '03/15/2023',
//         facility_name: 'TX Outpatient Telehealth',
//       },
//     ],
//     service_data_total: {
//       44444: 0,
//       44446: 700,
//     },
//     service_data_total_amount: {
//       44444: '$0',
//       44446: '$700.0',
//     },
//     service_data_by_facility_entity: [
//       {
//         entity_name: 'ENT 3 TX Healthcare',
//         facility_name: 'TX Outpatient Telehealth',
//         location: 'TX',
//         total_hours: '13.0',
//         total_serviced_unit: 700,
//         total_amount: '700.0',
//       },
//     ],
//     service_data_by_earning_code: [
//       {
//         earning_code: '44446',
//         earning_code_name: 'RM APPROVED PSYCHIATRY INTERVIEW',
//         initial_unit_charge_amount: '1.0',
//         service_units: 700,
//         unit_multiplier: 1,
//         total_rvus: 700,
//       },
//     ],
//     service_data_adjustments: [
//       {
//         service_date: '03/25/2023',
//         effective_date: '03/25/2023',
//         facility_name: 'TX Outpatient Telehealth',
//         earning_code: '44444',
//         service_units: 4,
//         charge_amount: '$0.0',
//       },
//       {
//         service_date: '03/25/2023',
//         effective_date: '03/20/2023',
//         facility_name: 'TX Outpatient Telehealth',
//         earning_code: '44446',
//         service_units: 20,
//         charge_amount: '$20.0',
//       },
//     ],
//     salary_contract: [],
//     total_rvus: 700,
//     gross_total: '$700.0',
//     rollover_total: '$20.0',
//     net_total: '$720.0',
//   },
// ];
